<template>
  <div class="view pa24">
    <div class="d-flex">
      <div class="d-flex">
        <el-select
            clearable
            v-model="chooseMB"
            style="width: 100%"
            placeholder="请选择模板"
        >
          <el-option
              v-for="(item,index) in tempList"
              :key="index"
              :label="item.templateTitle"
              :value="item.templateSettingsId"
          />
        </el-select>
        <el-button style="margin-left: 10px" type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button
            class="mr10"
            type="primary"
            @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
        >添加导航</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="name" align="center" label="导航名称" show-overflow-tooltip/>
        <el-table-column prop="templateSettingsName" align="center" label="模板名称" show-overflow-tooltip/>
        <el-table-column prop="jumpUrl" align="center" label="跳转地址" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.orderBy" min="0" @input="scope.row.orderBy=scope.row.orderBy.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column  align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.status"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            >
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.navigationId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加导航"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="78px"
      >
        <el-form-item label="导航名称" prop="title">
          <el-input clearable v-model="ruleForm.title"  maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable id="suggestId" min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" v-model="ruleForm.sort" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item  label="模板" prop="temp">
          <el-select
              clearable
              v-model="ruleForm.temp"
              style="width: 100%"
              placeholder="请选择模板"
          >
            <el-option
                v-for="(item,index) in tempList"
                :key="index"
                :label="item.templateTitle"
                :value="item.templateSettingsId"
            />
          </el-select>
        </el-form-item>
<!--        <el-form-item  label="页面" prop="pageY" v-if="ruleForm.temp == 5">-->
<!--          <el-select-->
<!--              clearable-->
<!--              v-model="ruleForm.pageY"-->
<!--              style="width: 100%"-->
<!--              placeholder="请选择页面"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="(item,index) in pageList"-->
<!--                :key="index"-->
<!--                :label="item.pageName"-->
<!--                :value="item.pageId"-->
<!--            />-->
<!--          </el-select>-->
<!--        </el-form-item>-->

        <el-form-item label="跳转链接" prop="skipLink">
          <el-radio-group v-model="ruleForm.skipLink" @change="ruleForm.jumpAddress=''">
            <el-radio :label="0">跳转内部页面</el-radio>
            <el-radio :label="1">跳转到网页</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 0&&ruleForm.temp == 1" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.link"
              style="width: 100%"
              placeholder="请选择内部页面"
          >
            <el-option
                v-for="(item) in linkList1"
                :key="item.linkId"
                :label="item.linkTitle　+ '　' + item.linkName"
                :value="item.linkName"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 0&&ruleForm.temp == 2" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.link"
              style="width: 100%"
              placeholder="请选择内部页面"
          >
            <el-option
                v-for="(item) in linkList2"
                :key="item.linkId"
                :label="item.linkTitle　+ '　' + item.linkName"
                :value="item.linkName"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 0&&ruleForm.temp == 3" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.link"
              style="width: 100%"
              placeholder="请选择内部页面"
          >
            <el-option
                v-for="(item) in linkList1"
                :key="item.linkId"
                :label="item.linkTitle　+ '　' + item.linkName"
                :value="item.linkName"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 0&&ruleForm.temp == 4" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.link"
              style="width: 100%"
              placeholder="请选择内部页面"
          >
            <el-option
                v-for="(item) in linkList1"
                :key="item.linkId"
                :label="item.linkTitle　+ '　' + item.linkName"
                :value="item.linkName"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 0&&ruleForm.temp == 5" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.pageY"
              style="width: 100%"
              placeholder="请选择页面"
          >
            <el-option
                v-for="(item,index) in pageList"
                :key="index"
                :label="item.pageName"
                :value="item.pageId"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.skipLink == 1" style="position: relative">
          <el-input clearable v-model="ruleForm.jumpAddress" placeholder="请输入URL..." maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {selectNavigationPageList,addNavigation,upNavigation,delNavigation,queryAllTemplateSettings,queryAssTempaltePage} from '@/api/website'
export default {
  name:'navSetup',
  data() {
    return {
      chooseMB:'',
      tempList:[],
      linkList1:[
        {linkId:1,linkName:'/home1',linkTitle:'首页'},
        {linkId:2,linkName:'/announcement1',linkTitle:'公告'},
        {linkId:3,linkName:'/memberFc1',linkTitle:'会员风采'},
        {linkId:4,linkName:'/dynamic1',linkTitle:'组织动态'},
        {linkId:5,linkName:'/videoList1',linkTitle:'组织视频'},
      ],
      linkList2:[
        {linkId:1,linkName:'/home2',linkTitle:'首页'},
        {linkId:2,linkName:'/announcement2',linkTitle:'公告'},
        {linkId:3,linkName:'/memberFc2',linkTitle:'会员风采'},
        {linkId:4,linkName:'/dynamic2',linkTitle:'组织动态'},
        {linkId:5,linkName:'/videoList2',linkTitle:'组织视频'},
      ],
      linkList3:[
        {linkId:1,linkName:'/home3',linkTitle:'首页'},
        {linkId:2,linkName:'/announcement3',linkTitle:'公告'},
        {linkId:3,linkName:'/memberFc3',linkTitle:'会员风采'},
        {linkId:4,linkName:'/dynamic3',linkTitle:'组织动态'},
        {linkId:5,linkName:'/videoList3',linkTitle:'组织视频'},
      ],
      linkList4:[
        {linkId:1,linkName:'/home4',linkTitle:'首页'},
        {linkId:2,linkName:'/announcement4',linkTitle:'公告'},
        {linkId:3,linkName:'/memberFc4',linkTitle:'会员风采'},
        {linkId:4,linkName:'/dynamic5',linkTitle:'组织动态'},
        {linkId:5,linkName:'/videoList4',linkTitle:'组织视频'},
      ],
      linkList5:[
        {linkId:1,linkName:'/home5',linkTitle:'首页'},
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      pageList:[],
      ruleForm: {
        title: "",
        jumpAddress: "",
        isState:0,
        sort:"",
        skipLink: 0,
        temp:'',
        link:'',
        pageY:''
      },
      rules: {
        title: [{ required: true, message: "请输入导航名称", trigger: "blur" }],
        jumpAddress: [{ required: true, message: "请输入跳转地址", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        isState: [{ required: true, message: "请选择状态", trigger: "blur" }],
        skipLink: [{ required: true, message: "请选择跳转链接", trigger: "blur" }],
        temp: [{ required: true, message: "请选择模板", trigger: "blur" }],
      },
      jurisdictionA:'',
      jurisdictionB:'',
      navigationId:'',
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.queryPage()
      }
    }
  },
  created() {
  },
  mounted(){
    this.getTemp()
    this.getList()
  },
  methods: {
    filterNames() {
      this.currentPage = 1;
      let data = {
        templateSettingsId: this.chooseMB
      };
      this.queryPage(data);
    },
    async queryPage(payload) {
      let data = {
        associationId:localStorage.getItem("associationId"),
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectNavigationPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in this.tempList) {
          for (let j in list) {
            if (list[j].status == 0) {
              list[j].status = true
            }else {
              list[j].status = false
            }
            if (list[j].templateSettingsId == this.tempList[i].templateSettingsId) {
              list[j]['templateSettingsName'] = this.tempList[i].templateTitle
            }
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    async getList() {
      let data = {
        associationId:localStorage.getItem("associationId"),
      };
      try {
        const result = await queryAssTempaltePage(data);
        let list = result.data;
        this.pageList = list;
      } catch (error) {
        this.pageList = []
      }
    },
    async getTemp(){
      let data = {};
      try {
        const result = await queryAllTemplateSettings(data);
        let list = result.data;
        this.tempList = list;
        this.queryPage();
      } catch (error) {

      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        jumpAddress: "",
        isState:0,
        sort:"",
        skipLink: 0,
        temp:'',
        link:'',
        pageY:''
      })
      this.bEdit = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        templateSettingsId: this.chooseMB
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        templateSettingsId: this.chooseMB
      };
      this.queryPage(data);
    },
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.sort = Number(data.orderBy);
      this.ruleForm.isState = data.status?0:1;
      this.ruleForm.skipLink = data.jumpType;
      if (data.jumpType == 0) {
        this.ruleForm.link = data.jumpUrl;
      } else {
        this.ruleForm.jumpAddress = data.jumpUrl;
      }

      this.ruleForm.temp = data.templateSettingsId;
      if (data.templateSettingsId == 5) {
        this.ruleForm.pageY = data.pageId;
      }

      this.navigationId = data.navigationId
      this.bdetermine = b;
      this.bEdit = b;
    },
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId:localStorage.getItem("associationId"),
              status:this.ruleForm.isState,
              name:this.ruleForm.title,
              orderBy:this.ruleForm.sort,
              templateSettingsId:this.ruleForm.temp,
              jumpType:this.ruleForm.skipLink
            };
            if (this.ruleForm.skipLink == 0) {
              data.jumpUrl = this.ruleForm.link
            } else {
              data.jumpUrl = this.ruleForm.jumpAddress
            }
            if ( this.ruleForm.temp == 5 ) {
              data.pageId = this.ruleForm.pageY
              data.jumpUrl = '/home5'
            }
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.navigationId = this.navigationId
              await upNavigation(data);
            } else {
              await addNavigation(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delNavigation({ navigationId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 上架视频 */
    async updateData(val) {
      let data = {
        videoId: val.videoId,
        state: val.state ? 0 : 1,
      };
      await updateData(data);
      await this.queryPage();
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          orderBy:row.orderBy,
          navigationId:row.navigationId
        }
        const result = await upNavigation(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row){
      if(row){
        console.log(row)
        let ajax = {
          navigationId:row.navigationId,
          status:row.status?0:1
        }
        const result = await upNavigation(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>j
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>


